import React, {forwardRef} from "react";
import classes from "classnames";

interface StackProps extends PrimitiveAsProps {
    backgroundColor?: "grey" | "turquoise" | "white";
    gap?: "none" | "extra-small" | "small" | "medium" | "large";
    onScroll?: (event: any) => void;
    paddingLeft?: "large" | "small";
    paddingRight?: "large" | "small";
    paddingTop?: "none" | "medium" | "large" | "small";
    paddingBottom?: "none" | "medium" | "large" | "small";
    rounded?: "small" | "medium";
    shadow?: "medium";
    verticalAlign?: "top" | "center" | "bottom";
    horizontalAlign?: "center" | "fill" | "left" | "right";
    id?: string;
}

export default forwardRef(function Stack(
    {
        "as": Tag = "div",
        backgroundColor,
        children,
        className,
        "data-cy": dataCy,
        gap = "medium",
        id,
        onScroll,
        paddingLeft,
        paddingRight,
        paddingTop,
        paddingBottom,
        rounded,
        shadow,
        verticalAlign,
        horizontalAlign = "fill",
    }: StackProps,
    ref,
) {
    return (
        <Tag
            className={classes(
                "ng-stack",
                `ng-stack-gap-${gap}`,
                {
                    [`ng-background-color-${backgroundColor}`]: backgroundColor,
                    [`ng-padding-left-${paddingLeft}`]: paddingLeft,
                    [`ng-padding-right-${paddingRight}`]: paddingRight,
                    [`ng-padding-top-${paddingTop}`]: paddingTop,
                    [`ng-padding-bottom-${paddingBottom}`]: paddingBottom,
                    [`ng-rounded-${rounded}`]: rounded,
                    [`ng-shadow-${shadow}`]: shadow,
                    [`ng-vertical-align-${verticalAlign}`]: verticalAlign,
                    [`ng-align-items-${horizontalAlign}`]: horizontalAlign,
                },
                className,
            )}
            data-cy={dataCy}
            onScroll={onScroll}
            ref={ref}
            id={id}
        >
            {children}
        </Tag>
    );
});
