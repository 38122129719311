import React, {forwardRef} from "react";
import classes from "classnames";

interface SuiteProps extends PrimitiveAsProps {
    backgroundColor?: "blue" | "turquoise" | "white" | "yellow";
    id?: string;
    justify?: "left" | "center" | "right" | "space-between";
    justifyMobile?: "left" | "center";
    onClick?: (event: any) => void;
    onScroll?: (event: any) => void;
    paddingLeft?: "large" | "small";
    paddingRight?: "large" | "small";
    paddingTop?: "large" | "small";
    paddingBottom?: "large" | "small";
    rounded?: "small" | "medium";
    shadow?: "medium";
    spacing?: "none" | "extra-small" | "small" | "medium" | "large";
    verticalSpacing?: "none" | "small" | "medium";
    verticalAlign?: "center" | "fill" | "top" | "baseline";
    wrap?: "never" | "mobile";
}

export default forwardRef(function Suite(
    {
        "as": Tag = "div",
        backgroundColor,
        children,
        className,
        "data-cy": dataCy,
        id,
        onClick,
        onScroll,
        paddingLeft,
        paddingRight,
        paddingTop,
        paddingBottom,
        rounded,
        shadow,
        spacing = "medium",
        verticalSpacing = "medium",
        verticalAlign,
        justify = "center",
        justifyMobile,
        wrap,
    }: SuiteProps,
    ref,
) {
    return (
        <Tag
            className={classes(
                "ng-suite",
                `ng-justify-${justify}`,
                `ng-spacing-${spacing}`,
                `ng-vertical-spacing-${verticalSpacing}`,
                {
                    [`ng-background-color-${backgroundColor}`]: backgroundColor,
                    [`ng-justify-mobile-${justifyMobile}`]: justifyMobile,
                    [`ng-padding-left-${paddingLeft}`]: paddingLeft,
                    [`ng-padding-right-${paddingRight}`]: paddingRight,
                    [`ng-padding-top-${paddingTop}`]: paddingTop,
                    [`ng-padding-bottom-${paddingBottom}`]: paddingBottom,
                    [`ng-rounded-${rounded}`]: rounded,
                    [`ng-shadow-${shadow}`]: shadow,
                    [`ng-vertical-align-${verticalAlign}`]: verticalAlign,
                    [`ng-wrap-${wrap}`]: wrap,
                },
                className,
            )}
            data-cy={dataCy}
            id={id}
            onClick={onClick}
            onScroll={onScroll}
            ref={ref}
        >
            {children}
        </Tag>
    );
});
