import React from "react";

import Suite from "@ng-components/primitives/Suite";

type Label = {
    id: string;
    required?: boolean;
    text: string;
};

export default function Label({id, required, text}: Label) {
    return (
        <label className="ng-label" htmlFor={id}>
            <Suite justify="space-between" wrap="never">
                <span>{text}</span>
                {required !== true && <span className="ng-label-optional">(Facultatif)</span>}
            </Suite>
        </label>
    );
}
