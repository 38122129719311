import React from "react";
import classes from "classnames";

interface ParagraphProps extends PrimitiveProps {
    isBold?: boolean;
    isItalic?: boolean;
    justify?: "left" | "center";
    justifyMobile?: "left" | "center";
    hideMobile?: boolean;
    lineClamp?: 2 | 3 | "none";
    size?: "small" | "medium" | "large" | "extra-large" | "legacy";
    textColor?: "black" | "green" | "grey" | "white" | "red" | "ultramarine";
    whiteSpace?: "nowrap" | "normal";
}

export default function Paragraph({
    children,
    className,
    "data-cy": dataCy,
    isBold = false,
    isItalic = false,
    justify = "left",
    justifyMobile,
    hideMobile = false,
    lineClamp,
    size = "medium",
    textColor,
    whiteSpace = "normal",
}: ParagraphProps) {
    return (
        <p
            className={classes(
                "ng-paragraph",
                `ng-justify-${justify}`,
                `ng-size-${size}`,
                {
                    "ng-text-bold": isBold,
                    "ng-text-italic": isItalic,
                    "ng-hide-mobile": hideMobile,
                    [`ng-text-color-${textColor}`]: textColor,
                    [`ng-justify-mobile-${justifyMobile}`]: justifyMobile,
                    [`ng-line-clamp-${lineClamp}`]: lineClamp,
                },
                `ng-whitespace-${whiteSpace}`,
                className,
            )}
            data-cy={dataCy}
        >
            {children}
        </p>
    );
}
