"use client";
import React, {useCallback} from "react";
import Link from "next/link";
import classes from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {TrackingData} from "@shared/types/editorial-types";

import {tracker} from "@business/components/common/Tracker";

export interface ButtonProps {
    "onClick"?: () => void;
    "className"?: string;
    "color"?: "yellow" | "blue" | "white";
    "trackingData"?: TrackingData;
    "data-cy"?: string;
    "disabled"?: boolean;
    "fullWidth"?: boolean;
    "icon"?: IconDefinition;
    "loading"?: boolean;
    "padding"?: "extra-small" | "small";
    "size"?: "legacy";
    "target"?: string;
    "text"?: string;
    "tooltip"?: string;
    "type"?: "submit";
    "url"?: string;
    "variant"?: "text" | "contained" | "outlined";
}

export default function Button({
    onClick,
    className,
    color = "yellow",
    trackingData,
    "data-cy": dataCy,
    disabled = false,
    fullWidth = false,
    icon,
    loading = false,
    padding,
    size,
    target = "_self",
    text,
    type,
    tooltip,
    url,
    variant,
}: ButtonProps) {
    const isDisabled = loading || disabled;

    const formattedClassName = classes(
        "ng-button-2",
        `ng-color-${color}`,
        {
            "ng-disabled": disabled,
            "ng-loading": loading,
            "ng-full-width": fullWidth,
            [`ng-size-${size}`]: size,
            [`ng-padding-${padding}`]: padding,
            [`ng-variant-${variant}`]: variant,
        },
        className,
    );

    const {trackClick, trackVisibility, container} = trackingData || {};

    const handleLinkClick = useCallback(() => {
        if (trackClick && container) tracker.clickLink({container, link: {target, text, url}});
        if (onClick) onClick();
    }, [trackClick, container, url]);

    const buttonLabel = (
        <>
            {icon && <FontAwesomeIcon className="ng-button-icon" fixedWidth icon={icon} />}
            {icon && text && <>&nbsp;</>}
            {text && <span className="ng-button-text">{text}</span>}
        </>
    );

    if (url && !disabled) {
        return (
            <Link
                className={formattedClassName}
                data-cy={dataCy}
                data-track-click={trackClick}
                data-track-visibility={trackVisibility}
                data-track-container-reference={container?.reference || null}
                href={url}
                target={target}
                title={tooltip}
                onClick={handleLinkClick}
            >
                {buttonLabel}
            </Link>
        );
    }

    return (
        <button
            className={formattedClassName}
            data-cy={dataCy}
            disabled={isDisabled}
            onClick={onClick}
            title={tooltip}
            type={type}
        >
            {buttonLabel}
        </button>
    );
}
